import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import './App.css';
import Stackblitz from './components/Stackblitz';



const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
  credentials: 'include',
  cache: new InMemoryCache(),
});

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#387EFF',
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <Stackblitz />
      </MuiThemeProvider>
    </ApolloProvider>
  )
}

export default App;
