import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { STACKBLITZ_EDIT_MODE_HEIGHT, STACKBLITZ_PREVIEW_MODE_HEIGHT } from "../constants";

export const StackblitzStyles = makeStyles((theme: Theme) =>
    createStyles({
        StackBlitzRoot: {
            "&>.stackblitz": {
                position: 'relative',
                borderBottom: "solid 0px",
                "&.preview": {
                    height: STACKBLITZ_PREVIEW_MODE_HEIGHT,
                    backgroundColor: theme.palette.type === "light" ? "#eee" : theme.palette.background.default,
                    overflow: 'hidden',
                    "&>iframe": {
                        border: "solid 0px",
                    },
                },
                "&.editor": {
                    height: STACKBLITZ_EDIT_MODE_HEIGHT,
                    overflow: 'hidden',
                    "&>iframe": {
                        border: "solid 0px",
                    },
                },
            },
            "&.fullscreen": {
                zIndex: 1200,
                "&>.stackblitz": {
                    borderBottom: "none !important",
                    width: '100vw !important',
                    height: 'calc(100vh - 56px) !important',
                    position: 'fixed',
                    top: "calc(env(safe-area-inset-top) + 55px)",
                    left: 0,
                    zIndex: 1200,
                    "&>iframe": {
                        zIndex: 1200,
                        border: "none !important",
                        height: "calc(100vh - 56px) !important"
                    },
                }
            }
        },
        actionBar: {
            padding: '0 4px',
            width: "100%",
            height: 34,
            display: 'flex',
            "&.light": {
                backgroundColor: '#eaeaea',
            },
            "&.dark": {
                backgroundColor: '#2d3138',
                color: "#bbb"
            },
        },
        actionBtn: {
            fontSize: '0.7rem',
            fontWeight: 400,
            minWidth: 40,
            padding: '4px 8px',
            "&.selected": {
                fontWeight: 'bold',
            }
        },
        fullScreenBtn: {
            width: 34,
            height: 34,
            marginLeft: 12
        },
        menuBtn: {
            width: 34,
            height: 34,
        },
        menuBtnDark: {
            width: 34,
            height: 34,
            color: "white"
        }
    })
);
