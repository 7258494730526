
export const STACKBLITZ_PROJECTS: any = {
    // frontend
    angular: {
        template: 'angular-cli',
        files: {
            'src/index.html': "",
            'src/main.ts': "",
            'src/styles.css': "",
            'angular.json': "",
        },
        openFile: ['src/styles.css,src/index.html,src/main.ts'],
        dependencies: {
            '@angular/animations': '*',
            '@angular/common': '*',
            '@angular/compiler': '*',
            '@angular/core': '*',
            '@angular/forms': '*',
            '@angular/platform-browser': '*',
            '@angular/platform-browser-dynamic': '*',
            '@angular/router': '*',
            'rxjs': '*',
            'tslib': '*',
            'zone.js': '*',
        }
    },
    typescript: {
        template: 'typescript',
        files: {
            'index.html': "",
            'index.ts': "",
            'styles.css': "",
        },
        openFile: ['styles.css,index.html,index.ts'],
        dependencies: {},
    },
    javascript: {
        template: 'javascript',
        files: {
            'index.js': "",
            'index.html': "",
        },
        dependencies: {}
    },
    html: {
        template: 'javascript',
        files: {
            'index.html': "",
            'index.js': "",
            'styles.css': "",
        },
        openFile: ['styles.css,index.js,index.html'],
        dependencies: {}
    },
    css: {
        template: 'polymer',
        files: {
            'index.html': "",
            'styles.css': "",
        },
        openFile: 'index.html,styles.css',
        dependencies: {}
    },
    'react-js': {
        template: 'create-react-app',
        files: {
            'public/index.html': "",
            'src/index.js': "",
            'src/App.js': "",
            'src/styles.css': "",
        },
        openFile: ['src/styles.css,src/App.js'],
        dependencies: {
            'react': '*',
            'react-dom': '*'
        }
    },
    'react-ts': {
        template: 'create-react-app',
        files: {
            'index.html': "",
            'index.tsx': "",
            'App.tsx': "",
            'styles.css': "",
        },
        openFile: ['styles.css,App.tsx'],
        dependencies: {
            'react': '*',
            'react-dom': '*',
            '@types/react': '*',
            '@types/react-dom': '*',
        }
    },
    // backend
    nodejs: {
        template: 'node',
        files: {
            'index.js': "",
            'package.json': "",
        },
        dependencies: {}
    },
    express: {
        template: 'node',
        files: {
            'index.js': "",
            'static/styles.css': "",
            'pages/index.html': "",
            'package.json': "",
        },
        openFile: ['static/styles.css,pages/index.html,index.js'],
        dependencies: {
            'express': '*',
        }
    },

    //other
    other: {
        template: 'javascript',
        files: {
            'README.md': "",
            'package.json': "",
        },
        dependencies: {}
    },
}

export async function getProject(id: string) {

    const project = STACKBLITZ_PROJECTS[id]

    if (!project) {
        throw new Error('bad project id')
    }

    const fileNames = Object.keys(project.files)

    await Promise.all(fileNames.map(fn => {
        const fileName = fn?.replace('src', 'src-fake')?.replace('public', 'public-fake')
        return fetch(`/assets/stackblitz-templates/${id}/${fileName}.txt`).then(res => res.text()).then(res => { project.files[fn] = res })
    }
    ));

    return project
}