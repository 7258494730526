import { useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import sdk, { VM } from "@stackblitz/sdk";
import { useCallback, useEffect, useState } from 'react';
import { STACKBLITZ_EDIT_MODE_HEIGHT, STACKBLITZ_PREVIEW_MODE_HEIGHT } from "../constants";
import { ICode } from "../interfaces/Code";
import IPost from "../interfaces/Post";
import IStackBlitzProject from "../interfaces/StackblitzProject";
import { GET_POST_CODE_QUERY } from "../queries/post";
import { StackblitzStyles } from "./Stackblitz.style";
import { getProject } from "./StackblitzProjects";


function Stackblitz() {

    const pathname = window.location.pathname
    let postId = null
    let dark: boolean = false

    if (pathname?.length && pathname?.startsWith('/')) {
        const parts = pathname?.split('/')
        postId = Number(parts[1])
        if (parts[2] === 'dark') {
            dark = true
        }
    }

    // const postId: number = 2311
    const postQuery = useQuery<{ postCode: IPost }>(GET_POST_CODE_QUERY, { variables: { postId }, skip: !postId })

    const [code, setCode] = useState<ICode | undefined>()
    const [stackblitzVM, setStackblitzVM] = useState<VM | null>(null)

    const classes = StackblitzStyles()
    // const theme = useTheme()

    // const [fullscreenStackblitz, setFullscreenStackblitz] = useState(false)
    const [selectedView, setSelectedView] = useState<'preview' | 'editor' | 'default'>(code?.defaultView ?? 'preview')
    const [menuOpened, setMenuOpened] = useState(false)

    stackblitzVM?.editor?.setTheme(dark ? "dark" : "light")

    // const handleFullScreenStackblitzClick = useCallback(() => {
    //     const root = document.getElementById('root')
    //     if (root) {
    //         root.classList.toggle('fixed')
    //         if (!fullscreenStackblitz) {
    //             console.log("will enter full screen => lock scroll")
    //             root.style.overflow = 'hidden'
    //         }
    //         else {
    //             console.log("will exit full screen")
    //             root.style.overflow = ''
    //         }
    //     }
    //     setFullscreenStackblitz(!fullscreenStackblitz)
    // }, [fullscreenStackblitz])

    const handleChangePreviewMode = useCallback((mode: 'preview' | 'editor' | 'default') => {
        setSelectedView(mode)
        stackblitzVM?.editor.setView(mode)
    }, [stackblitzVM?.editor])

    const handleTogleMenu = useCallback(() => {
        setMenuOpened(!menuOpened)
        stackblitzVM?.editor.showSidebar(menuOpened)
    }, [menuOpened, stackblitzVM?.editor])


    useEffect(() => {

        if (code?.files) {
            getStackblitzProjectConfig(undefined, code?.files, "share your code", code?.template).then((stackblitzProject) => {

                const StackBlitzOption: any = {
                    height: code?.defaultView === 'preview' ? STACKBLITZ_PREVIEW_MODE_HEIGHT : STACKBLITZ_EDIT_MODE_HEIGHT,
                    width: '100%',
                    theme: "light",
                    view: code?.defaultView,
                    clickToLoad: false,
                    hideNavigation: true,
                    hideExplorer: true,
                    hideDevTools: false,
                }
                if (stackblitzProject?.openFile) {
                    StackBlitzOption.openFile = stackblitzProject.openFile
                }
                else {
                    const files = Object.keys(stackblitzProject.files)?.filter(e => e !== "package.json")?.splice(0, 3)
                    if (files?.length)
                        StackBlitzOption.openFile = files?.join(',')
                }


                setTimeout(async () => {
                    setStackblitzVM(await sdk.embedProject("stackblitz", stackblitzProject, StackBlitzOption));
                    // setIsStackblitzLoading(false)
                }, 0);
            })
        }

        return () => {
            setStackblitzVM(null)
        }
    }, [code?.defaultView, code?.files, code?.template])

    useEffect(() => {
        if (postQuery?.data?.postCode?.code) {
            const code = JSON.parse(postQuery?.data?.postCode?.code)
            setCode(code)
        }
    }, [postQuery?.data?.postCode?.code])

    return (
        postId ?
            // <div className={`${classes.StackBlitzRoot} ${fullscreenStackblitz ? 'fullscreen' : ''}`}>
            <div className={classes.StackBlitzRoot}>
                <div className={`stackblitz ${code?.defaultView}`}>
                    <div className={`${classes.actionBar} ${dark ? "dark" : "light"}`}>
                        <div className='flex-grow'>
                            <IconButton className={classes.menuBtn} color="inherit" onClick={handleTogleMenu}>
                                <MenuIcon />
                            </IconButton>
                        </div>
                        {
                            //hide preview/both tabs when selected project is "other"
                            !code?.codeOnly &&
                            <>
                                <Button onClick={() => handleChangePreviewMode('editor')} className={`${selectedView === 'editor' ? 'selected' : ''} ${classes.actionBtn}`} color="inherit" size="small">Editor</Button>
                                <Button onClick={() => handleChangePreviewMode('preview')} className={`${selectedView === 'preview' ? 'selected' : ''} ${classes.actionBtn}`} color="inherit" size="small">Preview</Button>
                                <Button onClick={() => handleChangePreviewMode('default')} className={`${selectedView === 'default' ? 'selected' : ''} ${classes.actionBtn}`} color="inherit" size="small">Both</Button>
                            </>
                        }
                        {/* <IconButton className={`${classes.fullScreenBtn} ${fullscreenStackblitz ? 'selected' : ''}`} onClick={handleFullScreenStackblitzClick}>
                            <FullscreenIcon />
                        </IconButton> */}
                    </div>
                    <div id={"stackblitz"} ></div>
                </div>
            </div>
            : <div>ERROR</div>
    );
}

async function getStackblitzProjectConfig(project?: IStackBlitzProject, stackblitzVMSnapshot?: any, title?: string, template?: string) {

    const projectConfig = project?.id ? await getProject(project?.id) : { template }

    const stackblitzProject: any = {
        files: stackblitzVMSnapshot ? stackblitzVMSnapshot : projectConfig?.files,
        title: title,
        description: "Created by TheWide.com",
        template: projectConfig?.template,
        tags: ["stackblitz", "sdk"],
        settings: {
            compile: {
                clearConsole: false
            }
        },
        dependencies: projectConfig?.dependencies
    };

    if (projectConfig?.openFile) {
        stackblitzProject.openFile = projectConfig.openFile
    }

    return stackblitzProject
}

// const EditorButton = styled.button`
//     font-size: '0.7rem',
//     font-weight: 400,
//     min-width: 40,
//     padding: '4px 8px',
//     "&.selected": {
//         font-weight: 'bold',
//     }
// `

// const EditorButton = styled(Button)<ButtonProps>(({ theme }) => ({
//     width: 300,
//     color: theme.palette.success.main,
//     '& .MuiSlider-thumb': {
//       '&:hover, &.Mui-focusVisible': {
//         boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
//       },
//       '&.Mui-active': {
//         boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
//       },
//     },
//   }));

export default Stackblitz

